import nsApi from '@netsapiens/netsapiens-js/dist/api';

export default (user) => new Promise((resolve) => {
  nsApi.v2({
    object: 'video',
    action: 'get',
  }, `domains/~/users/${user.userId}/video`).then((host) => {
    console.debug('Iotum host:', host);
    resolve(host);
  }).catch((e) => {
    console.debug(e);
  });
});
