import React, { useCallback, useEffect, useRef } from 'react';
import { connect } from 'react-redux';

import * as CallbridgeSDK from '@iotum/callbridge-js';
import { Box } from '@material-ui/core';

import PageContainer from '../../containers/PageContainer';
import Logo from '../../components/Logo';
import AppMenu from '../../components/AppMenu';
import UserInfo from '../../components/UserInfo';
import { selectUser } from '../../../selectors';
import userShape from '../../../shapes/user';

const CallBridge = ({ user }) => {
  const callbirdgeContainer = useRef(null);
  const callbridgeWidget = useRef(null);
  const creds = {
    domain: user.iotum_domain,
    hostId: user.iotum_video_hostid,
    loginTokenPublicKey: user.iotum_video_key,
  };
  const areCredentialsValid = creds.loginTokenPublicKey && creds.domain && creds.hostId;

  const renderWidget = useCallback((container, { domain, loginTokenPublicKey, hostId }) => {
    console.debug('Render Widget loading...');

    const initWidget = () => {
      const meetWidget = new CallbridgeSDK.Dashboard(
        { domain, sso: { token: loginTokenPublicKey, hostId }, container },
        CallbridgeSDK.Service.Meet,
        {},
      );

      meetWidget.on('dashboard.NAVIGATE', (e) => {
        console.debug('navigate event', e);
      });

      meetWidget.on('room.READY', (e) => {
        console.debug('entering meeting:', e);
      });

      meetWidget.on('room.UNLOAD', () => {
        console.debug('room.UNLOAD event');
        callbridgeWidget.current.unload();
        callbridgeWidget.current = initWidget();
        callbridgeWidget.current.on('dashboard.READY', () => {
          callbridgeWidget.current.toggle(true);
          callbridgeWidget.current.load(CallbridgeSDK.Service.Meet);
        });
      });

      return meetWidget;
    };

    callbridgeWidget.current = initWidget();
    callbridgeWidget.current.toggle(true);
  }, []);

  useEffect(() => {
    if (areCredentialsValid) {
      renderWidget(callbirdgeContainer.current, creds);
    }

    return () => {
      callbridgeWidget.current?.unload();
    };
  }, [creds, renderWidget, areCredentialsValid]);

  return (
    <PageContainer>
      <Logo />
      <AppMenu />
      <UserInfo />
      <Box sx={{ marginTop: '10vh', height: '90vh', width: '100vw' }} ref={callbirdgeContainer} />
    </PageContainer>
  );
};

CallBridge.propTypes = {
  user: userShape.propTypesShape.isRequired,
};
const mapStateToProps = (state) => ({
  user: selectUser(state),
});
export default connect(mapStateToProps)(CallBridge);
