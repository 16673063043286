/* eslint-disable jsx-a11y/interactive-supports-focus */
import nsToken from '@netsapiens/netsapiens-js/dist/token';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { makeStyles } from '@material-ui/core';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

import AppsIcon from '@material-ui/icons/Apps';

const useStyles = makeStyles(() => ({
  root: {
    position: 'absolute',
    float: 'right',
    zIndex: 989,
    top: 20,
    right: 72,
    cursor: 'pointer',
    color: '#fff',
    padding: 8,
    background: 'rgba(0,0,0,.12)',
  },
  appMenu: {
    color: '#555',
    marginTop: 48,
    marginRight: 48,
    '& svg': {
      marginRight: 12,
    },
  },
  avatarContainer: {
    display: 'inline-block',
  },
  listGravatar: {
    opacity: 1,
    marginRight: 4,
    float: 'left',
  },
  listFiller: {
    marginTop: -4,
  },
  bkgColor: {
    backgroundColor: 'rgba(0,0,0,0.26)',
  },
  highlight: {
    backgroundColor: 'rgba(0,0,0,0.08)',
  },
}));

const AppMenu = ({ configs }) => {
  const classes = useStyles();

  const [appsMenuAnchorEl, setAppsMenuAnchorEl] = useState(null);

  const openAppsMenuMenu = (event) => {
    event.stopPropagation();
    setAppsMenuAnchorEl(event.target);
  };

  const handleAppsMenuClose = () => {
    setAppsMenuAnchorEl(null);
  };

  const portalTitle = configs.PORTAL_TITLE_USER || 'Portal';

  const attendantEnabled = configs.PORTAL_ATTENDANT_CONSOLE === 'yes';
  const attendantTitle = configs.PORTAL_ATTENDANT_CONSOLE_NAME || 'Attendant Console';

  const webPhoneEnabled = configs.PORTAL_ALLOW_WEB_PHONE === 'yes';
  const webPhoneTitle = configs.PORTAL_WEB_PHONE_NAME || 'SNAPmobile Web';

  const videoTitle = configs.PORTAL_VIDEO_NAME || 'SNAP.HD';

  const decodedToken = nsToken.getDecoded();
  const analyticsEnabled = configs.PORTAL_ANALYTICS_FEATURE_ENABLED === 'yes';
  const analyticsTitle = configs.PORTAL_ANALYTICS_FEATURE_NAME || 'SNAPanalytics';

  if (decodedToken && (decodedToken.user === 'guest' || decodedToken.user_scope === 'Meeting Guest')) return (<div />);

  return (
    <div>
      <IconButton
        id="appMenuBtn"
        tabIndex="0"
        onClick={openAppsMenuMenu}
        onKeyPress={openAppsMenuMenu}
        className={classnames('animate__animated animate__slow animate__fadeIn', classes.root)}
      >
        <AppsIcon />
      </IconButton>

      <Menu
        keepMounted
        anchorEl={appsMenuAnchorEl}
        onClose={handleAppsMenuClose}
        open={Boolean(appsMenuAnchorEl)}
        className={classes.appMenu}
      >
        <MenuItem onClick={() => { window.open('/portal', '_portal'); handleAppsMenuClose(); }}>
          {portalTitle}
        </MenuItem>

        {attendantEnabled
          && (
            <MenuItem onClick={() => { window.open('/portal/attendant', '_attendant'); handleAppsMenuClose(); }}>
              {attendantTitle}
            </MenuItem>
          )}
        {webPhoneEnabled
          && (
            <MenuItem onClick={() => { window.open('/webphone', '_webphone'); handleAppsMenuClose(); }}>
              {webPhoneTitle}
            </MenuItem>
          )}

        <MenuItem className={classes.highlight} onClick={() => { window.location = '/video'; handleAppsMenuClose(); }}>
          {videoTitle}
        </MenuItem>

        {analyticsEnabled
          && (
            <MenuItem onClick={() => { window.open('/analytics', '_analytics'); handleAppsMenuClose(); }}>
              {analyticsTitle}
            </MenuItem>
          )}
      </Menu>
    </div>
  );
};

AppMenu.propTypes = {
  configs: PropTypes.shape({
    PORTAL_TITLE_USER: PropTypes.string,
    PORTAL_ATTENDANT_CONSOLE: PropTypes.string,
    PORTAL_ATTENDANT_CONSOLE_NAME: PropTypes.string,
    PORTAL_ALLOW_WEB_PHONE: PropTypes.string,
    PORTAL_WEB_PHONE_NAME: PropTypes.string,
    PORTAL_VIDEO_NAME: PropTypes.string,
    PORTAL_ANALYTICS_FEATURE_ENABLED: PropTypes.string,
    PORTAL_ANALYTICS_FEATURE_NAME: PropTypes.string,
  }).isRequired,
};

const mapStateToProps = (state) => ({
  configs: state.configs,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({

}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(AppMenu);
