import _ from 'lodash';
import nsBrowser from '@netsapiens/netsapiens-js/dist/browser';
import nsToken from '@netsapiens/netsapiens-js/dist/token';
import nsUtils from '@netsapiens/netsapiens-js/dist/utils';
import { eventChannel } from 'redux-saga';
import {
  put, select, take, call,
} from 'redux-saga/effects';
import crypto from 'crypto-browserify';

import * as actions from '../../actions';
import * as selectors from '../../selectors';
import store from '../../store';

import getAllMessageSessions from '../../services/getAllMessageSessions';
import checkMediaPermission from '../../services/checkMediaPermission';
import hasDevices from '../../services/hasDevices';

import joinAudioBridge from './joinAudioBridge';
import { getDefaultAudioInput, getMediaDevices } from '../../utils/devices';

import {
  matchContact,
  matchMessage,
  matchDeletedMessage,
} from '../../utils';

import {
  ALLOWED_WEBINAR_ROLES,
  LAYOUT_TYPE_CONVERSATION,
  LAYOUT_TYPE_GRID,
  LAYOUT_TYPE_SPOTLIGHT,
} from '../../constants';
import * as events from '../../events';
import bugsnagClient from '../../bugsnag';

const md5 = require('md5');

const NEW_SOCKET_UC_ITEM = 'NEW_SOCKET_UC_ITEM';
const PARTICIPANT = 'PARTICIPANT';
const PREVIOUS_UC_ITEMS = 'PREVIOUS_UC_ITEMS';
const VIDEO_STATUS = 'VIDEO_STATUS';
const ENERGY_RECORD = 'ENERGY_RECORD';
const STATUS_RECORD = 'STATUS_RECORD';
const MEETING_UPDATE = 'MEETING_UPDATE';
const MEETING_EVENT = 'MEETING_EVENT';
const ATTENDEE_UPDATE = 'ATTENDEE_UPDATE';
const MESSAGE = 'MESSAGE';
const EXISTING_ATTENDEES = 'EXISTING_ATTENDEES';

export default function* nsSocketListeners() {
  try {
    const socket = yield select(selectors.selectSocket);

    // create channel for callbacks
    const channel = eventChannel((emitter) => {
      socket.on('participant', (data) => {
        emitter({ type: PARTICIPANT, data });
      });

      socket.on('ucinbox_item', (data) => {
        emitter({ type: NEW_SOCKET_UC_ITEM, data });
      });

      socket.on('ucinbox_previous', (data) => {
        if (data) {
          emitter({ type: PREVIOUS_UC_ITEMS, data });
        }
      });

      socket.on('video_status', (data) => {
        if (data) {
          emitter({ type: VIDEO_STATUS, data });
        }
      });

      socket.on('meeting', (data) => {
        if (data) {
          emitter({ type: MEETING_UPDATE, data });
        }
      });

      socket.on('meeting_event', (data) => {
        if (data) {
          emitter({ type: MEETING_EVENT, data });
        }
      });

      socket.on('attendee', (data) => {
        if (data) {
          emitter({ type: ATTENDEE_UPDATE, data });
        }
      });

      socket.on('existing_attendees', (data) => {
        if (data) {
          emitter({ type: EXISTING_ATTENDEES, data });
        }
      });

      socket.on('uc-mesg', (data) => {
        if (data) {
          emitter({ type: MESSAGE, data });
        }
      });

      // TODO need to put knob here
      socket.on('energy', (data) => {
        if (data) {
          emitter({ type: ENERGY_RECORD, data });
        }
      });

      socket.on('ncs_status', (data) => {
        if (data) {
          emitter({ type: STATUS_RECORD, data });
        }
      });

      // unsubscribe method
      return () => {};
    });

    // handle channel event
    while (true) {
      const res = yield take(channel);
      const combinedId = yield select((state) => selectors.selectConfig(state, 'combinedId'));

      switch (res.type) {
        case NEW_SOCKET_UC_ITEM: {
          res.data.isSelf = combinedId === res.data.id.replace(`_${res.data.ts}`, '');

          const items = yield select((state) => selectors.selectUcItems(state));
          const newItems = [...items, res.data];
          yield put(actions.setUcItems(newItems));

          const isOpen = yield select((state) => selectors.selectUcIsOpen(state));
          const tabValue = yield select((state) => selectors.selectUcTabValue(state));
          if (!isOpen || (isOpen && tabValue !== 'chat')) {
            const unreadCount = yield select((state) => selectors.selectUcUnReadCount(state));
            yield put(actions.setUcUnreadCount(unreadCount + 1));
          }
          break;
        }
        case PARTICIPANT: {
          const type = res.data.participant_match.indexOf('vb') === -1 ? 'call' : 'video';

          // only handle calls
          if (type === 'video') {
            continue; // eslint-disable-line no-continue
          }

          let id = res.data.aor_user.split('-')[0];
          id = id.replace(/[^0-9+]/g, '').replace('+', '');

          if (id) {
            const participants = yield select((state) => selectors.selectParticipants(state));
            const participant = participants[`${type}_${id}`];

            // check if the participant should be removed
            if (res.data.remove === 'yes' && participant) {
              if (participant) {
                yield put(events.removeParticipant(participant));
              }
            } else if ((res.data.mode === 'active' || res.data.mode === 'moh') && !participant) {
              // attempt to match one of the users contacts
              const contacts = yield select((state) => selectors.selectContacts(state));
              const contact = matchContact(contacts, id);

              // get the display name
              let displayName = res.data.name;
              if (contact) {
                displayName = contact.name;
              } else if (!displayName) {
                const localizationNumber = yield select((state) => selectors.selectConfig(state, 'PORTAL_LOCALIZATION_NUMBER_FORMAT'));
                displayName = nsUtils.formatPhoneNumber(id, localizationNumber);
              }

              let email;
              if (contact && contact.email) {
                email = contact.email; // eslint-disable-line prefer-destructuring
              }

              // get the gravatar
              let gravatar = null;
              if (contact && contact.gravatar) {
                gravatar = contact.gravatar; // eslint-disable-line prefer-destructuring
              }

              let initials = null;
              if (contact && contact.initials) {
                initials = contact.initials; // eslint-disable-line prefer-destructuring
              }

              let uid = null;
              if (contact && contact.uid) {
                uid = contact.uid; // eslint-disable-line prefer-destructuring
              }

              yield put(events.addParticipant({
                direction: 'inbound',
                displayName,
                email,
                gravatar,
                id: `call_${id}`,
                initials,
                type: 'call',
                status: 'active',
                uid,
                userId: id,
              }));
            }
          }
          break;
        }
        case PREVIOUS_UC_ITEMS: {
          for (let i = 0; i < res.data.length; i += 1) {
            res.data[i].isSelf = combinedId === res.data[i].id.replace(`_${res.data[i].ts}`, '');
          }

          yield put(actions.setUcItems(res.data));

          const isOpen = yield select((state) => selectors.selectUcIsOpen(state));
          const tabValue = yield select((state) => selectors.selectUcTabValue(state));
          if (!isOpen || (isOpen && tabValue !== 'chat')) {
            yield put(actions.setUcUnreadCount(res.data.length));
          }
          break;
        }
        case VIDEO_STATUS: {
          if (!res.data.userId) break;

          let participantId;
          if (res.data.type) {
            participantId = `${res.data.type}_${res.data.userId}`;
          } else {
            participantId = `video_${res.data.userId}`;
          }

          const participant = yield select((state) => selectors.selectParticipant(state,
            participantId));

          if (participant && participant.type === 'video' && combinedId !== res.data.userId) {
            if (participant.videoTrackMuted !== res.data.videoMuted) {
              participant.videoTrackMuted = res.data.videoMuted;
              yield put(events.updateParticipant(participant));

              // (VID-884) reSet video grid with uniq to force reRender
              const muteHide = yield select((state) => selectors.selectVideoMuteHide(state));
              if (muteHide) {
                const videoGridIds = yield select((state) => selectors.selectVideoGridIds(state));
                yield put(actions.setVideoGrid(_.uniq(videoGridIds)));
              }
            }
          }

          yield put(actions.updateMediaStatus(res.data));
          break;
        }
        case ENERGY_RECORD: {
          // set participants' isActive status here
          yield put(events.updateActiveStatus(res));
          // change the order of the grid here
          yield put(events.updateSpeakerOrder(res));
          // send notification to sfu for active speaker
          yield put(events.notifySfuSpeaker(res));
          break;
        }
        case ATTENDEE_UPDATE: {
          const attendee = res.data;
          const meeting = yield select(selectors.selectMeeting);
          const myAttendee = yield select(selectors.selectMyAttendee);
          const myAudioId = meeting.my_audio_id || myAttendee.audio_id || '';

          if (meeting && meeting.type === 'presentation' && myAttendee
            && !ALLOWED_WEBINAR_ROLES.includes(myAttendee.role)) {
            // We might need some better logic here for demotion.
            if (meeting.hide_viewers_list === 1 && !ALLOWED_WEBINAR_ROLES.includes(attendee.role)
              && myAttendee.attendee_id !== attendee.attendee_id) {
              break;
            }
          }

          const attendees = yield select(selectors.selectAttendeesList);
          const index = _.findIndex(attendees, { attendee_id: attendee.attendee_id });

          if (index === -1) {
            yield put(events.setAttendees([...attendees, attendee]));
          } else {
            // TODO: determine if the attendee should be removed based on status/video_status
            const existingAttendee = _.find(attendees, { attendee_id: attendee.attendee_id });
            if (String(existingAttendee.audio_id) === String(myAudioId)) {
              if (attendee.audio_participant_match && !attendee.audio_participant_match.includes('vb') && !attendee.audio_participant_match.includes('guest')) {
                yield put(events.endAudioBridge({
                  participantMatch: attendee.audio_participant_match,
                }));
              }
            }

            const updatedAttendee = { ...existingAttendee, ...attendee };

            let userId;
            if (_.get(updatedAttendee, 'id', '') === 'guest') {
              userId = `guest_${updatedAttendee.attendee_id}`;
            } else {
              userId = _.get(updatedAttendee, 'id', '');
            }

            const mediaStatus = yield select(selectors.selectUserMediaStatus);

            // eslint-disable-next-line max-len
            const previousWebinarRoleAllowed = ALLOWED_WEBINAR_ROLES.includes(existingAttendee.role);
            const currentWebinarRoleAllowed = ALLOWED_WEBINAR_ROLES.includes(updatedAttendee.role);

            // role has been demoted
            if (meeting.type === 'presentation' && previousWebinarRoleAllowed && !currentWebinarRoleAllowed) {
              const participants = yield select(selectors.selectParticipants);

              const ids = Object.keys(participants);
              for (let i = 0; i < ids.length; i += 1) {
                const participant = participants[ids[i]];
                if (`video_${userId}` === participant.id) {
                  yield put(events.removeParticipant(participant));
                }
              }
            }

            if (updatedAttendee.isSelf) {
              if (updatedAttendee.status_video && updatedAttendee.status_video === 'disconnected') {
                // We really should not be here, but but lets reupdate our video status.
                updatedAttendee.status_video = 'connected';
                yield put(events.postVideoStatus('connected'));
              }

              if (updatedAttendee.audio_restricted) {
                if (!existingAttendee.audio_restricted) {
                  yield put(actions.snackBarMessage('HOST_MUTED_AUDIO'));
                }

                if (!mediaStatus.audioMuted) {
                  yield put(events.toggleAudioMute());
                }
              } else if (existingAttendee.audio_restricted) {
                yield put(actions.openAlertDialog({
                  content: 'ATTENDEE_ALERT_HEARD_BY_OTHERS',
                  actions: [{
                    id: 'deviceSetupBtn',
                    label: 'DEVICE_SETUP',
                    disabled: !mediaStatus.hasCamDevice && !mediaStatus.hasMicDevice,
                    onClick: () => {
                      store.dispatch(actions.closeAlertDialog());
                      store.dispatch(events.openDeviceReconfigModal(true));
                    },
                  }],
                }));
              }

              if (updatedAttendee.video_restricted) {
                if (!existingAttendee.video_restricted) {
                  yield put(actions.snackBarMessage('HOST_MUTED_VIDEO'));
                }

                if (!mediaStatus.videoMuted) {
                  yield put(events.toggleVideoMute());
                }
              } else if (existingAttendee.video_restricted) {
                yield put(actions.openAlertDialog({
                  content: 'ATTENDEE_ALERT_SEEN_BY_OTHERS',
                  actions: [{
                    id: 'deviceSetupBtn',
                    label: 'DEVICE_SETUP',
                    disabled: !mediaStatus.hasCamDevice && !mediaStatus.hasMicDevice,
                    onClick: () => {
                      store.dispatch(actions.closeAlertDialog());
                      store.dispatch(events.openDeviceReconfigModal(true));
                    },
                  }],
                }));
              }

              if (updatedAttendee.screenshare_restricted) {
                if (!existingAttendee.screenshare_restricted) {
                  yield put(actions.snackBarMessage('HOST_MUTED_SCREEN_SHARE'));
                }
                yield put(events.endScreenShare());
              } else if (existingAttendee.screenshare_restricted) {
                yield put(actions.snackBarMessage('HOST_UNMUTED_SCREEN_SHARE'));
              }

              if (updatedAttendee.access_restricted) {
                // end video session
                const room = yield select(selectors.selectVideoRoom);
                room.closeRoom();

                // end audio session
                if (String(updatedAttendee.audio_id) === String(myAudioId)) {
                  if (attendee.audio_participant_match && !attendee.audio_participant_match.includes('vb')) {
                    yield put(events.endAudioBridge({
                      participantMatch: attendee.audio_participant_match,
                    }));
                  }
                }

                yield put(events.showMeetingHubPage());
              }

              // role has been promoted
              if (!previousWebinarRoleAllowed && currentWebinarRoleAllowed) {
                // yield initMedia(mediaStatus);

                yield put(actions.openAlertDialog({
                  content: updatedAttendee.role === 'host'
                    ? 'ATTENDEE_ALERT_ELEVATED_HOST_ROLE'
                    : 'ATTENDEE_ALERT_ELEVATED_PRESENTER_ROLE',
                  actions: [{
                    id: 'deviceSetupBtn',
                    label: 'DEVICE_SETUP',
                    disabled: !mediaStatus.hasCamDevice && !mediaStatus.hasMicDevice,
                    onClick: () => {
                      store.dispatch(actions.closeAlertDialog());
                      store.dispatch(events.openDeviceReconfigModal(true));
                    },
                  }],
                  showOk: false,
                }));

                if (String(updatedAttendee.audio_id) === String(myAudioId)) {
                  yield put(actions.setMyAttendee(updatedAttendee));
                }
                const messageSessions = yield getAllMessageSessions(meeting);
                yield put(actions.setChatSessions(messageSessions));
                // subscribe to the host chat room
                socket.subscriptions.push({
                  type: 'uc-mesg',
                  domain: myAttendee.domain,
                  filter: `hosts${crypto.createHash('md5').update(meeting.chat_sessionid).digest('hex')}`,
                  meeting_id: meeting.id.toString(),
                  attendeeId: myAttendee.attendee_id,
                  bearer: nsToken.get(),
                });

                socket.subscriptions.forEach((sub) => socket.emit('subscribe', sub));
                yield put(actions.setSocket(socket));

                const availableDevices = yield hasDevices();
                if (availableDevices.mic) {
                  const micPermission = yield checkMediaPermission({ audio: true, video: false });
                  const devices = yield getMediaDevices();
                  yield put(actions.updateMediaStatus({
                    ...mediaStatus,
                    audioInputDevice: getDefaultAudioInput(devices),
                    hasMicPermissions: micPermission,
                  }));
                  yield call(joinAudioBridge);
                }
              }

              // role has been demoted
              if (previousWebinarRoleAllowed && !currentWebinarRoleAllowed) {
                // if in webinar, toggle off media on demotion
                if (meeting.type === 'presentation') {
                  if (!mediaStatus.audioMuted) {
                    yield put(events.toggleAudioMute());
                  }

                  if (!mediaStatus.videoMuted) {
                    yield put(events.toggleVideoMute());
                  }
                }
                if (String(updatedAttendee.audio_id) === String(myAudioId)) {
                  yield put(actions.setMyAttendee(updatedAttendee));
                }

                const messageSessions = yield getAllMessageSessions(meeting);
                yield put(actions.setChatSessions(messageSessions));
                // disconnect from socket updates for host chat room
                const decodedToken = nsToken.getDecoded();
                const unsubscribeData = {
                  domain: decodedToken.domain,
                  type: 'host-chat',
                  roomName: `hosts${crypto.createHash('md5').update(meeting.chat_sessionid).digest('hex')}`,
                };

                socket.emit('disconnectRoom', unsubscribeData);
              }
            } else if (ALLOWED_WEBINAR_ROLES.includes(myAttendee.role)
              && ALLOWED_WEBINAR_ROLES.includes(updatedAttendee.role)
            ) {
              if (updatedAttendee.audio_id && updatedAttendee.audio_participant_match
                && !updatedAttendee.audio_participant_match.includes('vb')
              ) {
                // handle the case when another host calls in via PSTN
                if (meeting.wait_for_host === 1 && meeting.status === 'started' && updatedAttendee.status === 'active') {
                  yield put(events.updateNcsParticipant('leader', () => {}, updatedAttendee.audio_id));
                }
              }
            }

            // Client room only handles news peers to add a muted participant.
            // This will trigger based on the attendee updates
            // Use case: promoting/demoting an attendee
            const participant = yield select((state) => selectors.selectParticipant(state, `video_${userId}`));
            if (meeting.type === 'presentation') {
              if (!participant && _.get(updatedAttendee, 'role') && ALLOWED_WEBINAR_ROLES.includes(_.get(updatedAttendee, 'role'))) {
                const room = yield select(selectors.selectVideoRoom);
                const globalStats = selectors.selectVideoAllStats(store.getState());

                let email;
                if (_.get(updatedAttendee, 'email')) {
                  if (_.isArray(updatedAttendee.email)) {
                    ({ email } = updatedAttendee);
                  } else {
                    email = [updatedAttendee.email];
                  }
                }

                if (updatedAttendee && room && !(updatedAttendee.status_video === 'disconnected' || updatedAttendee.status_video === '')) {
                  yield put(events.addParticipant({
                    ...updatedAttendee,
                    email,
                    direction: 'inbound',
                    displayName: updatedAttendee.name,
                    id: `video_${userId}`,
                    isMuted: true,
                    videoTrackMuted: true,
                    isSelf: false,
                    videoStat: globalStats,
                    show: true,
                    status: 'accepted',
                    type: 'video',
                    userId,
                    uid: `${userId}@${updatedAttendee.domain}`,
                  }));
                }
              } else if (participant && !ALLOWED_WEBINAR_ROLES.includes(_.get(updatedAttendee, 'role'))) {
                yield put(events.removeParticipant(participant));
              }
            }

            attendees.splice(index, 1, updatedAttendee);
            yield put(events.setAttendees(attendees));
          }
          break;
        }
        case EXISTING_ATTENDEES: {
          const newExisting = res.data;
          const attendees = yield select(selectors.selectAttendeesList);

          if (typeof attendees !== 'undefined' && attendees) {
            for (let i = 0; i < attendees.length; i += 1) {
              const index = _.findIndex(newExisting, { attendee_id: attendees[i].attendee_id });
              if (index === -1) {
                newExisting.push(attendees[i]);
              }
            }
          }

          yield put(events.setAttendees(newExisting));
          break;
        }
        case MEETING_UPDATE: {
          console.debug('MEETING_UPDATE', res.data);
          const meetingUpdate = res.data;
          const meeting = yield select(selectors.selectMeeting);
          if (meetingUpdate.status === 'complete' || meetingUpdate.status === 'ended') {
            const decodedToken = nsToken.getDecoded();
            if (decodedToken && decodedToken.user_scope && decodedToken.user_scope === 'Meeting Guest') {
              localStorage.removeItem('ns_t');
            }

            nsBrowser.removeQuery('id');

            yield put(actions.showMeetingEnded());
          }

          if (meetingUpdate.status === 'started') {
            if (meeting.status !== 'started') {
              yield put(actions.snackBarMessage('MEETING_STARTED'));
            }

            const room = yield select(selectors.selectVideoRoom);
            if (_.get(room, 'pendingStart')) {
              const attendees = yield select(selectors.selectAttendeesList);
              const myAttendee = _.find(attendees, (attendee) => attendee.isSelf);
              const mediaStatus = yield select(selectors.selectUserMediaStatus);

              let isVideoPermitted = true;
              switch (meeting.video_share) {
                case 'host':
                  isVideoPermitted = myAttendee && myAttendee.role === 'host';
                  break;
                case 'presenters':
                  isVideoPermitted = myAttendee && ['host', 'presenter'].includes(myAttendee.role);
                  break;
                default:
                  break;
              }

              const isVideoRestricted = !myAttendee || !isVideoPermitted || myAttendee.video_restricted; // eslint-disable-line max-len

              room.consume = true;
              room.produce = (!mediaStatus.videoMuted || mediaStatus.screenShareStream) && !isVideoRestricted; // eslint-disable-line max-len
              room.pendingStart = false;

              room.connectSNAPhd();

              actions.setRoom(room);
            }
          }

          if (meetingUpdate && meetingUpdate.lock && !meeting.lock) {
            yield put(actions.snackBarMessage('HOST_LOCKED_MEETING'));
          }

          if (meetingUpdate && !meetingUpdate.lock && meeting.lock) {
            yield put(actions.snackBarMessage('HOST_UNLOCKED_MEETING'));
          }

          if (meetingUpdate.record_enabled && !meeting.record_enabled) {
            yield put(actions.snackBarMessage('HOST_STARTED_RECORD'));
          }

          // force layout change
          if (meeting.layout !== meetingUpdate.layout) {
            if (meetingUpdate.layout === LAYOUT_TYPE_SPOTLIGHT
              || meetingUpdate.layout === LAYOUT_TYPE_CONVERSATION
              || meetingUpdate.layout === LAYOUT_TYPE_GRID
            ) {
              yield put(events.setLayout({
                layout: meetingUpdate.layout,
                updateMeeting: false, // prevents circular loop
              }));
            }
          }

          // show screenshare request modal
          const myAttendee = yield select(selectors.selectMyAttendee);
          if ((meetingUpdate.presenter && !meeting.presenter)
            || (meetingUpdate.presenter === myAttendee.attendee_id
              && meeting.presenter !== myAttendee.attendee_id)
          ) {
            if (meetingUpdate.presenter === myAttendee.attendee_id) {
              yield put(actions.showScreenshareRequestModal(true));
            }
          } else if (meetingUpdate.presenter !== meeting.presenter) {
            // force end screenshare if user is sharing
            const { screenShareMuted } = yield select(selectors.selectUserMediaStatus);
            if (!screenShareMuted) {
              const room = yield select(selectors.selectVideoRoom);
              room.endScreenShare();
            }
          }

          yield put(actions.setMeeting(meetingUpdate));

          break;
        }
        case MEETING_EVENT: {
          // add this to the chat for the entire meeting
          const meeting = yield select(selectors.selectMeeting);
          const sessions = yield select(selectors.selectChatSessions);
          // might not be set up before the page finishes loading

          if (meeting && meeting.chat_sessionid && sessions[meeting.chat_sessionid]) {
            sessions[meeting.chat_sessionid].messages.push(res.data);
            yield put(actions.setChatSessions(sessions));
          }

          if (res.data.event === 'part_depart' && meeting.my_audio_id === res.data.details.audio_id) {
            const mediaStatus = yield select(selectors.selectUserMediaStatus);
            if (mediaStatus.audioInputDevice === 'External Phone') {
              const devices = yield getMediaDevices();
              yield put(actions.updateMediaStatus({
                ...mediaStatus,
                audioInputDevice: getDefaultAudioInput(devices),
              }));
              yield call(joinAudioBridge);
            }
          }

          break;
        }
        case MESSAGE: {
          const sessions = yield select(selectors.selectChatSessions);
          const clonedSessions = _.cloneDeep(sessions);

          // get my attendee for domain, id, and attendee_id
          const attendees = yield select(selectors.selectAttendeesList);
          const myAttendee = _.find(attendees, (attendee) => attendee.isSelf);

          if (res.data.status === 'deleted') {
            const newMessagesDeleted = matchDeletedMessage(clonedSessions[res.data.session_id].messages, res.data); // eslint-disable-line max-len
            if (newMessagesDeleted) {
              clonedSessions[res.data.session_id].messages = newMessagesDeleted;
              yield put(actions.setChatSessions(clonedSessions));
            }
            break;
          }

          // if type is chat-media, need to make a medialink
          if (res.data.type && res.data.type === 'chat-media') {
            // if the message already exists in the recentmessages, remove the loading and return
            // replace original sending to not be "sending" anymore
            if (clonedSessions && res.data.session_id && clonedSessions[res.data.session_id]) {
              const newMessagesNoLoading = matchMessage(clonedSessions[res.data.session_id].messages, res.data); // eslint-disable-line max-len
              if (newMessagesNoLoading) {
                clonedSessions[res.data.session_id].messages = newMessagesNoLoading;
                clonedSessions[res.data.session_id].lastMessage = res.data;
                clonedSessions[res.data.session_id].lastMessageTimestamp = _.get(res.data, 'timestamp');
                clonedSessions[res.data.session_id].lastRemoteAttendeeId = _.get(res.data, 'from_attendee_id');
                clonedSessions[res.data.session_id].lastStatus = _.get(res.data, 'status');
                yield put(actions.setChatSessions(clonedSessions));
                break;
              }
            }

            // get time
            const date = new Date();
            const year = date.getUTCFullYear();
            const month = (date.getUTCMonth() < 9 ? '0' : '') + (date.getUTCMonth() + 1);
            const day = (date.getUTCDate() < 10 ? '0' : '') + date.getUTCDate();
            const hour = (date.getUTCHours() < 10 ? '0' : '') + date.getUTCHours();
            const minute = (date.getUTCMinutes() < 10 ? '0' : '') + date.getUTCMinutes();
            const second = (date.getUTCSeconds() < 10 ? '0' : '') + date.getUTCSeconds();
            const timestamp = year + month + day + hour + minute + second;

            // compile authstr and hash
            const decodedToken = nsToken.getDecoded();
            let id = myAttendee.id; // eslint-disable-line prefer-destructuring
            if (decodedToken.user_scope === 'Meeting Guest' && id === '') {
              id = 'guest';
            }

            let authStr = myAttendee.domain + id;
            authStr = authStr + res.data.id + timestamp + myAttendee.attendee_id;
            authStr = md5(authStr);

            // get meeting for meeting id
            const meeting = yield select(selectors.selectMeeting);

            // set medialink on inbound/outbound message
            let mediaLink = `https://${window.location.hostname}/ns-api/?object=message&action=read_media`;
            mediaLink = `${mediaLink}&meeting_id=${meeting.id}&domain=${myAttendee.domain}&user=${id}&time=${timestamp}&id=${res.data.id}&auth=${authStr}`;
            res.data.remotepath = mediaLink;
          }

          // append to recent messages and set
          const recentMessages = yield select(selectors.selectRecentMessages);
          let clonedRecentMessages = _.cloneDeep(recentMessages);
          if (typeof clonedRecentMessages === 'undefined') {
            clonedRecentMessages = [];
          }

          // give message the attendee
          res.data.attendee = _.find(attendees, { attendee_id: res.data.from_attendee_id }) || { gravatar: 'blank' };
          if (res.data.attendee !== myAttendee) {
            // limit to max length of 3 for recent messages
            if (clonedRecentMessages.length > 2) {
              clonedRecentMessages.length = 2;
            }
            yield put(actions.setRecentMessages(clonedRecentMessages));
            if (!_.find(clonedRecentMessages, { id: res.data.id })) {
              clonedRecentMessages.unshift(res.data);
            }
          }

          // create new session if needed
          if (!clonedSessions[res.data.session_id]) {
            let remoteAttendeeId = res.data.from_attendee_id;
            // if it is your own attendee_id, use the term_uid parsed
            // should use myAttendee.attendee_id instead ?
            if (res.data.from_attendee_id === myAttendee.id) {
              remoteAttendeeId = res.data.term_uid.remote.substr(0, res.data.term_uid.indexOf('@'));
            }
            clonedSessions[res.data.session_id] = {
              id: res.data.session_id,
              type: 'private', // assuming every new session would be a private message
              messages: [],
              remoteAttendeeId,
              unreadMessageCount: 0,
            };
          }

          // check if duplicate message in session, if so should not push
          if (_.find(_.get(clonedSessions, [res.data.session_id, 'messages'], []), { id: res.data.id })) {
            break;
          }

          // initialize unreadMessageCount so does not appear as NaN
          if (typeof clonedSessions[res.data.session_id].unreadMessageCount === 'undefined'
            || Number.isNaN(clonedSessions[res.data.session_id].unreadMessageCount)) {
            clonedSessions[res.data.session_id].unreadMessageCount = 0;
          }

          // increment unread count
          let unreadCount = yield select((state) => selectors.selectUcUnReadCount(state));
          unreadCount += 1;
          yield put(actions.setUcUnreadCount(unreadCount));

          clonedSessions[res.data.session_id].unreadMessageCount += 1;
          clonedSessions[res.data.session_id].lastMessage = res.data;
          clonedSessions[res.data.session_id].lastMessageTimestamp = _.get(res.data, 'timestamp');
          clonedSessions[res.data.session_id].lastRemoteAttendeeId = _.get(res.data, 'from_attendee_id');
          clonedSessions[res.data.session_id].lastStatus = _.get(res.data, 'status');
          clonedSessions[res.data.session_id].messages.push(res.data);
          yield put(actions.setChatSessions(clonedSessions));
          break;
        }
        default:
      }
    }
  } catch (err) {
    console.error(err);
    bugsnagClient.notify(err, (event) => {
      // eslint-disable-next-line no-param-reassign
      event.context = 'saga: nsSocketListeners';
    });
  }
}
