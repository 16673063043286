import nsApi from '@netsapiens/netsapiens-js/dist/api';

export default (domain) => new Promise((resolve) => {
  nsApi.v2({
    object: 'domain',
    action: 'get',
  }, `domains/${domain}`).then((data) => {
    console.debug('Domain:', data);
    resolve(data);
  }).catch((e) => {
    console.debug(e);
  });
});
