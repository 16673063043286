import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  logo: {
    position: 'absolute',
    marginTop: '24px',
    marginLeft: '24px',
    maxWidth: 400,
    maxHeight: 184,
    zIndex: 1,
    background: 'white',
  },
}));

const Logo = ({ logo }) => {
  const classes = useStyles();

  if (!logo) {
    return null;
  }

  return (
    <div className={classes.logo}>
      <img src={logo} alt="logo" />
    </div>
  );
};

Logo.defaultProps = {
  logo: null,
};

Logo.propTypes = {
  logo: PropTypes.string,
};

const mapStateToProps = (state) => ({
  logo: state.layout.logo,
});

export default connect(mapStateToProps)(Logo);
