import nsBrowser from '@netsapiens/netsapiens-js/dist/browser';
import { call, put, select } from 'redux-saga/effects';
import * as actions from '../../actions';
import { selectUser, selectContacts } from '../../selectors';
import { setMeeting, saveMeeting, joinConference } from '../../events';
import * as services from '../../services';
import bugsnagClient from '../../bugsnag';

// eslint-disable-next-line import/prefer-default-export
export function* showMeetingConfigBypass() {
  try {
    yield put(actions.showLoaderPage());
    const user = yield select(selectUser);
    const maxMeeting = yield select((state) => state.configs.videoConferenceMaxAttendees);
    const maxPresentation = yield select((state) => state.configs.videoPresentationMaxAttendees);
    const defaultPassword = (yield select((state) => state.configs.PORTAL_VIDEO_DEFAULT_PASSCODE)) === 'yes';
    const contacts = yield select((state) => selectContacts(state));
    const requested = nsBrowser.getQuery('users');
    const id = nsBrowser.getQuery('id');
    let meeting;
    const saveMode = 'new';
    if (typeof id !== 'undefined' && id !== '') {
      meeting = yield call(services.getCreatedMeetingInfo, {
        user,
        maxMeeting: parseInt(maxMeeting, 10),
        maxPresentation: parseInt(maxPresentation, 10),
        configType: 'start',
        defaultPassword,
        contacts,
        requested,
        id,
      });
    } else {
      meeting = yield call(services.getNewMeetingInfo, {
        user,
        maxMeeting: parseInt(maxMeeting, 10),
        maxPresentation: parseInt(maxPresentation, 10),
        configType: 'start',
        defaultPassword,
        contacts,
        requested,
        id,
      });
    }

    yield put(setMeeting(meeting));
    yield put(saveMeeting(saveMode));
    yield put(joinConference(meeting.id));
  } catch (err) {
    yield put(actions.snackBarError('UNABLE_TO_CONFIG'));
    yield put(actions.showMeetingHubPage());

    console.error(err);
    bugsnagClient.notify(err, (event) => {
      // eslint-disable-next-line no-param-reassign
      event.context = 'saga: showMeetingConfigBypass';
    });
  }
}
