export const UC_DRAWER_WIDTH = 380;
export const ALLOWED_WEBINAR_ROLES = ['host', 'presenter'];

/** audio */
export const AUDIO_DEVICE = 'audio/AUDIO_DEVICE';
export const AUDIO_SESSION = 'audio/AUDIO_SESSION';
export const CONFIGURATION = 'audio/CONFIGURATION';
export const UA = 'audio/UA';
export const REMOTE_AUDIO = 'audio/REMOTE_AUDIO';
export const PARTICIPANT_MATCH = 'audio/PARTICIPANT_MATCH';

/** conf id */
export const CHAT_SESSIONS = 'chat/CHAT_SESSIONS';
export const CHAT_SESSION_IN_FOCUS = 'chat/CHAT_SESSION_IN_FOCUS';
export const RECENT_MESSAGES = 'chat/RECENT_MESSAGES';
export const SHOW_DELETE_MESSAGE = 'layout/SHOW_DELETE_MESSAGE';

/** chat attachments */
export const ADD_CHAT_ATTACHMENT = 'chat/ADD_CHAT_ATTACHMENT';
export const CHAT_ATTACHMENTS = 'chat/CHAT_ATTACHMENTS';
export const REMOVE_CHAT_ATTACHMENT = 'chat/REMOVE_CHAT_ATTACHMENT';
export const SEND_CHAT_ATTACHMENTS = 'chat/SEND_CHAT_ATTACHMENTS';

/** conf id */
export const CONF_ID_ERROR_ID = 'conf-id-form/CONF_ID_ERROR_ID';

/** configs */
export const CONFIG = 'configs/CONFIG';

/** contacts */
export const CONTACTS = 'contacts/CONTACTS';

/** layout */
export const LOGO = 'layout/LOGO';
export const SHOW_ADD_USER = 'layout/SHOW_ADD_USER';
export const SHOW_BG_IMAGE = 'layout/SHOW_BG_IMAGE';
export const SHOW_BROWSER_SUPPORT_PAGE = 'layout/SHOW_BROWSER_SUPPORT_PAGE';
export const SHOW_CONF_ID_FORM_PAGE = 'layout/SHOW_CONF_ID_FORM_PAGE';
export const SHOW_CONF_SETTINGS_PAGE = 'layout/SHOW_CONF_SETTINGS_PAGE';
export const SHOW_DEVICE_SETTINGS_PAGE = 'layout/SHOW_DEVICE_SETTINGS_PAGE';
export const SHOW_LOADER_PAGE = 'layout/SHOW_LOADER_PAGE';
export const SHOW_LOGIN_FORM_PAGE = 'layout/SHOW_LOGIN_FORM_PAGE';
export const SHOW_LANDING_FORM_PAGE = 'layout/SHOW_LANDING_FORM_PAGE';
export const SHOW_MEETING_CONFIG = 'layout/SHOW_MEETING_CONFIG';
export const SHOW_MEETING_CONFIG_BYPASS = 'layout/SHOW_MEETING_CONFIG_BYPASS';
export const SHOW_MEETING_ENDED = 'layout/SHOW_MEETING_ENDED';
export const SHOW_MEETING_ERROR_PAGE = 'meeting-config/SHOW_MEETING_ERROR_PAGE';
export const SHOW_MEETING_TIME_ERROR_PAGE = 'meeting-config/SHOW_MEETING_TIME_ERROR_PAGE';
export const SHOW_MEETING_HUB_PAGE = 'layout/SHOW_MEETING_HUB_PAGE';
export const SHOW_MEETING_NOT_FOUND_PAGE = 'layout/SHOW_MEETING_NOT_FOUND_PAGE';
export const SHOW_MEETING_PASSWORD_PAGE = 'layout/SHOW_MEETING_PASSWORD_PAGE';
export const SHOW_MEETINGS_SCROLL_PAGE = 'layout/SHOW_ALL_MEETINGS_PAGE';
export const SHOW_MFA_FORM_PAGE = 'layout/SHOW_MFA_FORM_PAGE';
export const SHOW_PENDING_PERMISSIONS_DIALOG = 'layout/SHOW_PENDING_PERMISSIONS_DIALOG';
export const SHOW_PERMISSIONS_DIALOG = 'layout/SHOW_PERMISSIONS_DIALOG';
export const SHOW_SSO_ENROLL_PAGE = 'layout/SHOW_SSO_ENROLL_PAGE';
export const SHOW_SUBSCRIBER_SELECT_PAGE = 'layout/SHOW_SUBSCRIBER_SELECT_PAGE';
export const SHOW_REGISTER_FORM_PAGE = 'layout/SHOW_REGISTER_FORM_PAGE';
export const SHOW_REGISTER_SUCCESS_PAGE = 'layout/SHOW_REGISTER_SUCCESS_PAGE';
export const SHOW_SCREENSHARE_REQUEST_MODAL = 'layout/SHOW_SCREENSHARE_REQUEST_MODAL';
export const SHOW_UC_LICENSE_PAGE = 'layout/SHOW_UC_LICENSE_PAGE';
export const SHOW_VIDEO_GRID_PAGE = 'layout/SHOW_VIDEO_GRID_PAGE';
export const SHOW_CALLBRIDGE_PAGE = 'layout/SHOW_CALLBRIDGE_PAGE';
export const SHOW_DEVICE_RECONFIG_MODAL = 'layout/SHOW_DEVICE_RECONFIG_MODAL';
export const SET_MIV = 'layout/SET_MIV';

/** application alerts and notices */
export const CLOSE_ALERT_DIALOG = 'notices/CLOSE_ALERT_DIALOG';
export const OPEN_ALERT_DIALOG = 'notices/OPEN_ALERT_DIALOG';
export const CLOSE_REJECT_DIALOG = 'notices/CLOSE_REJECT_DIALOG';
export const OPEN_REJECT_DIALOG = 'notices/OPEN_REJECT_DIALOG';
export const SNACK_BAR = 'notices/SNACK_BAR';
export const SNACK_BAR_MESSAGE = 'notices/SNACK_BAR_MESSAGE';
export const SNACK_BAR_ERROR = 'notices/SNACK_BAR_ERROR';
export const SNACK_BAR_SUCCESS = 'notices/SNACK_BAR_SUCCESS';

/** login form */
export const LOGIN_ERROR_MSG = 'login-form/LOGIN_ERROR_MSG';
export const LOGIN_IMAGE = 'login-form/LOGIN_IMAGE';

/** media status */
export const UPDATE_MEDIA_STATUS = 'media-status/UPDATE_MEDIA_STATUS';
export const REMOVE_MEDIA_STATUS = 'media-status/REMOVE_MEDIA_STATUS';

/** meetings */
export const MEETINGS = 'meetings/MEETINGS';
export const MEETINGS_LOADING = 'meetings/MEETINGS_LOADING';
export const ROOMS = 'meetings/ROOMS';

/** meeting config */
export const MEETING_CONFIG = 'meeting-config/MEETING_CONFIG';

/** attendees */
export const SPEAKER_ACTIVITY = 'attendees/SPEAKER_ACTIVITY';
export const ATTENDEES_LIST = 'attendees/ATTENDEES_LIST';
export const CONNECTED_ATTENDEES = 'attendees/CONNECTED_ATTENDEES';
export const MY_ATTENDEE = 'attendees/MY_ATTENDEE';

/** screen share */
export const SCREENSHARE_IS_SELF = 'screenShare/SCREENSHARE_IS_SELF';
export const SCREENSHARE_IS_SHARING = 'screenShare/SCREENSHARE_IS_SHARING';
export const SCREENSHARE_BLOCKED = 'screenShare/SCREENSHARE_BLOCKED';
export const SCREENSHARE_OVERLAY_DISMISSED = 'screenShare/SCREENSHARE_OVERLAY_DISMISSED';

/** socket */
export const IS_CONNECTED = 'socket/IS_CONNECTED';
export const SOCKET = 'socket/SOCKET';

/** uc inbox */
export const UC_IS_OPEN = 'ucInbox/UC_IS_OPEN';
export const UC_ITEMS = 'ucInbox/UC_ITEMS';
export const UC_TAB_VALUE = 'ucInbox/UC_TAB_VALUE';
export const UC_UNREAD_COUNT = 'ucInbox/UC_UNREAD_COUNT';

/** users */
export const USER = 'user/USER';
export const LOGOUT_USER = 'user/LOGOUT_USER';

/** video */
export const LAYOUT_TYPE_SPOTLIGHT = 'spotlight';
export const LAYOUT_TYPE_CONVERSATION = 'conversation';
export const LAYOUT_TYPE_GRID = 'grid';
export const LAYOUT_TYPE_PICTURE = 'picture';
export const LAYOUT_ROTATE_LAND = 'landscape';
export const LAYOUT_ROTATE_VERT = 'vertical';
export const VIDEO_DYNAMIC_SPEAKER = 'video/VIDEO_DYNAMIC_SPEAKER';
export const VIDEO_TEMP_DYNAMIC_SPEAKER = 'video/VIDEO_TEMP_DYNAMIC_SPEAKER';
export const VIDEO_DYNAMIC_SPEAKER_COOLDOWN = 'video/VIDEO_DYNAMIC_SPEAKER_COOLDOWN';
export const VIDEO_DYNAMIC_SPEAKER_COOLDOWN_SFU = 'video/VIDEO_DYNAMIC_SPEAKER_COOLDOWN_SFU';
export const MUTED_NOTIFY = 'video/MUTED_NOTIFY';
export const MUTED_HINTS = 'video/MUTED_HINTS';
export const VIDEO_GRID = 'video/VIDEO_GRID';
export const TEMP_VIDEO_GRID = 'video/TEMP_VIDEO_GRID';
export const VIDEO_LAYOUT = 'video/VIDEO_LAYOUT';
export const VIDEO_PARTICIPANTS = 'video/VIDEO_PARTICIPANTS';
export const VIDEO_TRAY_OPEN = 'video/VIDEO_TRAY_OPEN';
export const VIDEO_MUTE_HIDE = 'video/VIDEO_MUTE_HIDE';
export const VIDEO_HIDE_SELF = 'video/VIDEO_HIDE_SELF';
export const VIDEO_ALL_STATS = 'video/VIDEO_ALL_STATS';
export const ROTATE_ORIENTATION = 'video/ROTATE_ORIENTATION';

/** video room */
export const ROOM = 'video-room/ROOM';
export const LAYOUT_PRE_SCREENSHARE = 'video-room/LAYOUT_PRE_SCREENSHARE';
export const SNAPHD_VOLLEY = 'video-room/SNAPHD_VOLLEY';
export const CONSUMERS = 'video-room/CONSUMERS';
export const CAMERA_MAP = 'video-room/CAMERA_MAP';

/** ****** MediaSoup v3 Constants ******* */

/* room */
export const SET_ROOM_URL = 'room/SET_ROOM_URL';
export const SET_ROOM_STATE = 'room/SET_ROOM_STATE';
export const SET_ROOM_ACTIVE_SPEAKER = 'room/SET_ROOM_ACTIVE_SPEAKER';
export const SET_ROOM_STATS_PEER_ID = 'room/SET_ROOM_STATS_PEER_ID';
export const SET_FACE_DETECTION = 'room/SET_FACE_DETECTION';
export const REMOVE_PEER = 'room/REMOVE_PEER';
