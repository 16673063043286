/* eslint-disable no-use-before-define */
import _ from 'lodash';
import {
  all, call, delay, fork, put, select, race,
} from 'redux-saga/effects';
import nsApi from '@netsapiens/netsapiens-js/dist/api';
import nsBrowser from '@netsapiens/netsapiens-js/dist/browser';
import nsPortal from '@netsapiens/netsapiens-js/dist/portal';
import nsToken from '@netsapiens/netsapiens-js/dist/token';
import nsUcLicense from '@netsapiens/netsapiens-js/dist/uc-license';
import md5 from 'md5';

import bugsnagClient from '../../bugsnag';
import store from '../../store';
import * as constants from '../../constants';
import * as actions from '../../actions';
import * as events from '../../events';
import * as selectors from '../../selectors';
import * as services from '../../services';

// utilities
import getDeviceInfo from '../../utils/getDeviceInfo';
import matchContact from '../../utils/matchContact';
import {
  getDefaultAudioInput,
  getDefaultAudioOutput,
  getDefaultVideo,
  getMIVVideo,
  getMediaDevices,
} from '../../utils/devices';

// services
import addDialRule from './services/addDialRule';
import browserSupport, { BROWSER_CHECK_FAIL } from './services/browserSupport';
import fetchContacts from './services/fetchContacts';
import fetchLogo from './services/fetchLogo';
import fetchLoginImage from './services/fetchLoginImage';
import fetchUser from './services/fetchUser';
import { MISSING_CONF_ID } from '../../services/confIdCheck';
import checkMediaPermission from '../../services/checkMediaPermission';
import mapCameraDevices from '../../services/mapCameraDevices';
import fetchIotumCreds from './services/fetchIotumCreds';

// events
import addParticipants from './addParticipants';
import configureSdk from './configureSdk';
import joinAudioBridge, { AUDIO_TERMINATED } from './joinAudioBridge';
import nsSocketConnect from './nsSocketConnect';
import nsSocketListeners from './nsSocketListeners';
import nsSocketSubscribe from './nsSocketSubscribe';
import registerAudioUa from './registerAudioUa';
import setFavicon from './setFavicon';
import setTitle from './setTitle';
import userUiConfigs from './userUiConfigs';
import { initMediaStreams, setMeeting, authenticateGuest } from '../event-sagas';
import fetchDomain from './services/fetchDomain';

// sdk error constants
const {
  API_TOKEN_REFRESH_FAIL,
  INVALID_TOKEN,
  NO_PORTAL_SESSION,
  NO_PORTAL_TOKEN,
  NO_STORED_TOKEN,
  UC_LICENSE_EXPIRED,
} = nsApi;

// these go across multiple sagas and need to be kept track of
let audioUaTask;
let decodedToken;
let isLoggedIn;
let userTask;
let ucCheck;

/**
 * Initial starting point for loading and configuring the app
 */
export function* preLogin() {
  try {
    if (window.location.protocol === 'http:') {
      window.location = `https:${window.location.href.substring(window.location.protocol.length)}`;
    }

    yield put(actions.showLoaderPage());

    localStorage.setItem('nsVideoCallLive', false);
    if (localStorage.getItem('ns_t_cache')) {
      // case where we were just kicked out out of a guest session.
      localStorage.setItem('ns_t', localStorage.getItem('ns_t_cache'));
      localStorage.removeItem('ns_t_cache');
      window.location.reload();
    }

    const registerId = yield select((state) => selectors.selectConfig(state, 'registerId'));

    // configure the sdk with the app name and portal/api url
    yield call(configureSdk);

    // verify the uc licensing is valid
    ucCheck = yield call(nsPortal.ucCheck);
    yield put(actions.setConfig({ apiHash: ucCheck.api_hash }));

    // if this line is reached the user has uc access
    // otherwise the catch will change the state to display the uc-license-msg component
    // get server ui configs and save to the core configs module
    yield call(userUiConfigs, nsToken.getDecoded());

    // fetch branding images
    const logo = yield call(fetchLogo, nsToken.getDecoded());
    yield put(actions.setLogo(logo));

    const loginImage = yield call(fetchLoginImage, nsToken.getDecoded());
    yield put(actions.setLoginImage(loginImage));
    yield put(actions.showBgImage(true));

    if (registerId) {
      isLoggedIn = false;
      yield call(registerPage);
      return;
    }

    const deviceInfo = getDeviceInfo();
    if (deviceInfo.isMiv) {
      yield put(actions.setMIV(1));
    } else {
      if (deviceInfo.isMobile || deviceInfo.isTouchScreen) {
        yield put(actions.setMIV(2));
      }
      // test if the browser is supported
      const configBrowsers = yield select(selectors.selectBrowsers);
      if (!nsBrowser.getQuery('redirect_uri')) {
        yield call(browserSupport, configBrowsers);
      }
    }

    // if this line is reached the user is using a supported browser
    // otherwise the catch will change the state to display the browser-support-msg component
    // call sdk proxy authentication
    yield call(nsApi.proxyAuthentication, ucCheck.api_hash);
    // set isLoggedIn so that the ui configs for the user won't be fetched again
    isLoggedIn = true;

    // if this line is reached, the user authenticated successfully
    // otherwise the catch will change the state to display the login-form component
    // call the next generator function to continue the load process
    nsBrowser.removeQuery('a');
    yield call(postLogin);
  } catch (err) {
    yield put(actions.showBgImage(true));

    const registerId = yield select((state) => selectors.selectConfig(state, 'registerId'));
    const attendeeId = yield select((state) => selectors.selectConfig(state, 'attendeeId'));

    // error handler
    switch (err) {
      case BROWSER_CHECK_FAIL:
        yield put(actions.showBrowserSupportPage());
        break;
      case UC_LICENSE_EXPIRED: {
        // fetch the uc license notice
        // ui configurable
        const notice = yield nsUcLicense.ucLicenseNotice();
        // change the application state to display the uc-license-msg component
        yield put(actions.showUCLicenseMsgPage(notice));
        break;
      }
      case API_TOKEN_REFRESH_FAIL:
      case INVALID_TOKEN:
      case NO_PORTAL_SESSION:
      case NO_PORTAL_TOKEN:
      case NO_STORED_TOKEN: {
        if (registerId) {
          isLoggedIn = false;
          yield call(registerPage);
          return;
        }
        if (attendeeId) {
          isLoggedIn = false;
          yield call(guestWithAttendeeId);
          return;
        }

        // remove the user query field from the url
        nsBrowser.removeQuery('user');
        // change the application state to display the login-form / landing-page
        if (nsBrowser.getQuery('l') && nsBrowser.getQuery('l') === 'u') {
          yield put(actions.showLoginFormPage());
        } else if (nsBrowser.getQuery('id')) {
          yield put(actions.showLandingFormPage());
        } else {
          yield put(actions.showLoginFormPage());
        }
        nsBrowser.removeQuery('l');

        yield put(actions.showBgImage(true));
        break;
      }
      default: {
        console.error(err);
        bugsnagClient.notify(err, (event) => {
          // eslint-disable-next-line no-param-reassign
          event.context = 'saga: preLogin';
        });

        let message = _.get(err, 'response.data');

        if (!message) {
          message = `Unhandled Runtime Error.\n\r${err}`;
        }

        yield put(actions.showUCLicenseMsgPage(message));
        break;
      }
    }
  }
}

export function* registerPage() {
  try {
    const registrationId = yield select((state) => selectors.selectConfig(state, 'registerId'));
    const meeting = yield call(services.getMeetingByRegId, { registrationId });

    yield put(events.setMeeting(meeting));

    // fetch and set the favicon
    // fetches from the api for custom branding
    yield fork(setFavicon);
    yield put(actions.showRegisterFormPage());
  } catch (err) {
    switch (err) {
      case MISSING_CONF_ID: {
        const meetings = yield call(services.getMeetings, {
          domain: decodedToken.domain,
          user: decodedToken.user,
        });
        yield put(actions.setMeetings(meetings));
        yield put(events.showMeetingHubPage());
        break;
      }
      case 'NO_MEETING': {
        yield put(actions.showMeetingNotFoundPage());
        break;
      }
      default:
        console.error(err);
        bugsnagClient.notify(err, (event) => {
          // eslint-disable-next-line no-param-reassign
          event.context = 'saga: registerPage';
        });

        yield put(actions.showUCLicenseMsgPage(err));
    }
  }
}

export function* guestWithAttendeeId() {
  try {
    const attendeeId = yield select((state) => selectors.selectConfig(state, 'attendeeId'));
    const confId = yield select((state) => selectors.selectConfig(state, 'confId'));
    const meeting = yield call(services.getMeetingByIdAndAttendee, {
      attendeeId,
      meetingId: confId,
      apiHash: ucCheck.api_hash,
    });

    const attendeesArray = JSON.parse(meeting.attendees);
    const token = nsToken.getDecoded();
    // eslint-disable-next-line max-len
    const matchingAttendee = attendeesArray.filter((attendee) => attendee.attendee_id === token.attendee_id);

    if (matchingAttendee.length > 0 && matchingAttendee[0].name.includes('guest,,,')) {
      if (meeting.allow_public_with_id) {
        nsBrowser.removeQuery('a');
        yield put(actions.showLandingFormPage());
      } else {
        nsBrowser.removeQuery('a');
        nsBrowser.removeQuery('id');
        yield put(events.logoutUser(false));
        yield put(actions.showMeetingErrorPage('ATTENDEE_ALREADY_ACTIVE'));
      }
      return;
    }

    if (meeting && meeting.attendee_name) {
      yield put(actions.setConfig({ guest: meeting.attendee_name }));
    }

    if (meeting && meeting.attendee_email) {
      yield put(actions.setConfig({ guestEmail: meeting.attendee_email }));
    }

    yield fork(setFavicon);

    // remove the user query field from the url
    nsBrowser.removeQuery('user');
    // change the application state to display the login-form component
    yield put(actions.showLoginFormPage());
  } catch (err) {
    switch (err) {
      default:
        console.error(err);
        bugsnagClient.notify(err, (event) => {
          // eslint-disable-next-line no-param-reassign
          event.context = 'saga: guestWithAttendeeId';
        });
    }
  }
}

function* emitMediaStatus(deviceInfo) {
  const meeting = yield select(selectors.selectMeeting);
  const socket = yield select(selectors.selectSocket);
  const metaData = { meeting_id: meeting.id, domain: meeting.domain };

  const userMediaStatus = yield select(selectors.selectUserMediaStatus);

  if (deviceInfo?.miv) {
    userMediaStatus.audioMuted = deviceInfo.defaultMic;
    userMediaStatus.videoMuted = deviceInfo.defaultCam;
  }
  const status = _.pick(userMediaStatus, [
    'audioMuted',
    'hasCamDevice',
    'hasCamPermissions',
    'hasMicDevice',
    'hasMicPermissions',
    'screenShareMuted',
    'userId',
    'videoAspectRatio',
    'videoMuted',
  ]);
  socket.emit(
    'video_status',
    { ...metaData, ...status },
  );
}

/**
 * Continuation of the load process after the user is authenticated
 */
export function* postLogin() {
  console.debug('SAGA - postLogin');
  try {
    localStorage.setItem('nsVideoCallLive', false);
    decodedToken = nsToken.getDecoded();

    // set a keepalive check
    setInterval(() => localStorage.getItem('ns_t') || window.location.reload(), 2500);

    yield fork(nsSocketConnect);

    // set the userId for the app
    yield put(actions.setConfig({ userId: decodedToken.user }));

    // set the guestId for the app
    let guestId = '';
    if (decodedToken.user_scope === 'guest' || decodedToken.user_scope === 'Meeting Guest') {
      guestId = `_${decodedToken.attendee_id}`;
    }
    yield put(actions.setConfig({ guestId }));

    // set combined userId, this is necessary to make guest unique
    const combinedId = `${decodedToken.user}${guestId}`;
    yield put(actions.setConfig({ combinedId }));

    // determine if ui configs should be fetched again
    if (!isLoggedIn) {
      yield call(userUiConfigs, decodedToken);
    }

    const guestEmail = yield select((state) => selectors.selectConfig(state, 'guestEmail'));
    const guestName = yield select((state) => selectors.selectConfig(state, 'guestName'));

    // fetch the user
    const userRequest = { ...decodedToken, userId: combinedId, guestId };
    if (!userRequest.user_email && guestEmail) {
      userRequest.user_email = guestEmail;
    }

    if (!userRequest.displayName && guestName) {
      userRequest.displayName = guestName;
    }

    const user = yield call(fetchUser, userRequest);
    if (!user) {
      yield put(actions.showLoginFormPage());
      return;
    }

    const { configs } = store.getState();

    if (configs.PORTAL_PREMIUM_VIDEO_ENABLED && configs.PORTAL_PREMIUM_VIDEO_ENABLED.toLowerCase() === 'yes') {
      // Reseller has been enabled
      const domain = yield call(fetchDomain, decodedToken.domain);

      let videoDomainSuspended = false;
      let iotumVideoStatus;
      try {
        iotumVideoStatus = domain['iotum-video-status'] && domain['iotum-video-status'] !== undefined
          ? JSON.parse(domain['iotum-video-status']) : {};
      } catch (e) {
        iotumVideoStatus = { status: domain['iotum-video-status'] };
      }
      if (iotumVideoStatus?.status === 'suspended') {
        videoDomainSuspended = true;
      }

      // check at domain level if company_id has been created and != suspended
      if (!!domain.iotum_company_id && !videoDomainSuspended) {
        const creds = yield call(fetchIotumCreds, user);
        if (creds.error_code) {
          console.error('Invalid Premium Video creds.', creds);
        } else {
          user.iotum_domain = creds?.iotum_domain;
          user.iotum_video_hostid = creds?.host_id;
          user.iotum_video_key = creds?.login_token_public_key;
        }

        const hasIotumCreds = !!user.iotum_domain
          && !!user.iotum_video_hostid
          && !!user.iotum_video_key;

        if (hasIotumCreds) {
          // Premium video is enabled navigate browser to Iotum portal
          let redirectURI = nsBrowser.getQuery('redirect_uri');
          if (redirectURI) {
            const redirectURL = new URL(redirectURI);
            redirectURL.searchParams.set('host_id', user.iotum_video_hostid);
            redirectURL.searchParams.set('login_token_public_key', user.iotum_video_key);
            window.location = redirectURL.href;
            return;
          }
          redirectURI = 'conf/conferences/create';

          // https://awqacore02.netsapiens.com/video/?redirect_uri=https%3A%2F%2Fleftcoaststaging.meet-staging.netsapiens.com%2Fauth
          window.location = `https://${user.iotum_domain}/auth?host_id=${user.iotum_video_hostid}&login_token_public_key=${user.iotum_video_key}&redirect_url=${redirectURI}&events=false`;
          return;
        }
        // creds are not found for host, but domain is configured
        store.dispatch(events.logoutUser());
        const notice = 'Your account is not enabled for this service';
        yield put(actions.showUCLicenseMsgPage(notice));

        return;
      }
    }
    if (!configs.PORTAL_VIDEO || configs.PORTAL_VIDEO.toLowerCase() !== 'yes') {
      store.dispatch(events.logoutUser());
      const notice = 'Your account is not enabled for this service';
      yield put(actions.showUCLicenseMsgPage(notice));
      return;
    }

    const configBrowsers = yield select(selectors.selectBrowsers);
    if (nsBrowser.getQuery('redirect_uri')) {
      yield call(browserSupport, configBrowsers);
    }

    if (user && !user.email && guestEmail) {
      user.email = guestEmail;
    }

    // re-fetch images if they're different for user/domain
    const userLogo = yield call(fetchLogo, decodedToken);
    const layout = yield select(selectors.selectLayout);

    const userLoginImage = yield call(fetchLoginImage, decodedToken);
    const loginImage = yield select((state) => selectors.selectLoginImage(state));
    if (layout.logo !== userLogo || loginImage !== userLoginImage) {
      yield put(actions.showBgImage(false));

      yield setTimeout(() => {
        store.dispatch(actions.setLogo(userLogo));
        store.dispatch(actions.setLoginImage(userLoginImage));
        store.dispatch(actions.showBgImage(true));
      }, 2000);
    }

    if (user.email === '') {
      user.email = [];
    }
    if (!_.isArray(user.email)) {
      user.email = [user.email];
    }

    user.isActive = false; // set user as not active on intial login/reload
    user.isAudible = false; // set user as not active on intial login/reload

    yield put(actions.setUser(user));

    // fetch and set the favicon
    // fetches from the api for custom branding
    yield fork(setFavicon);

    // set browser tab title
    yield fork(setTitle);

    // get and set contacts for add users/invites
    if (decodedToken.user_scope !== 'guest' && decodedToken.user_scope !== 'Meeting Guest') {
      const contacts = yield call(fetchContacts, {
        domain: decodedToken.domain,
        user: decodedToken.user,
      });

      yield put(actions.setContacts(contacts));
    }

    const deviceInfo = getDeviceInfo();
    if (deviceInfo.isMiv) {
      store.dispatch(actions.setMIV(1));
    } else if (deviceInfo.isMobile || deviceInfo.isTouchScreen) {
      store.dispatch(actions.setMIV(2));
    }

    yield call(processRoute);
  } catch (err) {
    console.error(err);
    bugsnagClient.notify(err, (event) => {
      // eslint-disable-next-line no-param-reassign
      event.context = 'saga: postLogin';
    });
    yield put(actions.showUCLicenseMsgPage(err));
  }
}

/**
 * Continuation of the login process if the user needs to select a subscriber to login as
 */
export function* subscriberSelect({ payload }) {
  try {
    const { subscriberList } = payload;
    yield put(actions.showSubscriberSelectPage(subscriberList));
  } catch (err) {
    console.error(err);
    bugsnagClient.notify(err, (event) => {
      // eslint-disable-next-line no-param-reassign
      event.context = 'saga: subscriberSelect';
      event.addMetadata('payload', payload);
    });
  }

  return true;
}

/**
 * Handle inbound routes and state pops
 */
export function* processRoute() {
  const urlPath = window.location.pathname.split('/');
  const route = urlPath.length >= 2 ? urlPath[2] : null;
  try {
    switch (route) {
      case 'upcoming': {
        yield put(events.showMeetingsScrollPage('future', false, urlPath[3], urlPath[4]));
        return;
      }
      case 'past': {
        yield put(events.showMeetingsScrollPage('past', false, urlPath[3], urlPath[4]));
        return;
      }
      case 'start': {
        if (nsBrowser.getQuery('users')) {
          yield put(events.showMeetingConfigBypass(null));
        } else {
          yield put(events.showMeetingConfig('new', 'start', null));
        }

        return;
      }
      case 'schedule': {
        yield put(events.showMeetingConfig('new', 'schedule', null));
        return;
      }
      default: {
        if (getParameterByName('init') === 'start') {
          if (nsBrowser.getQuery('users')) {
            yield put(events.showMeetingConfigBypass(null));
          } else {
            yield put(events.showMeetingConfig('new', 'start', null));
          }
          return;
        }
        const layout = yield select((state) => state.layout);
        // prevent browser back and show confirmation dialog
        if (layout.showVideoGridPage) {
          // add the id back if it was removed from the url
          if (!getParameterByName('id')) {
            const meeting = yield select((state) => state.meetingConfig.meeting);
            nsBrowser.setQuery({ name: 'id', value: meeting.id });
          }
          // open dialog and wait for response
          yield put(actions.openAlertDialog({
            content: 'MEETING_LEAVE_DIALOG',
            showOk: false,
            actions: [{
              id: 'meetingCancelBtn',
              label: 'MEETING_CANCEL',
              onClick: () => {
                store.dispatch(actions.closeAlertDialog());
              },
            }, {
              id: 'meetingLeaveBtn',
              label: 'MEETING_LEAVE',
              onClick: () => {
                store.dispatch(actions.closeAlertDialog());
                nsBrowser.removeQuery('id');
                window.location.reload();
              },
            }],
          }));
          return;
        }

        const confId = getParameterByName('id');

        decodedToken = nsToken.getDecoded();

        if (confId) {
          yield call(initConference);
          return;
        }
        throw MISSING_CONF_ID;
      }
    }
  } catch (err) {
    switch (err) {
      case MISSING_CONF_ID: {
        if (!decodedToken || decodedToken.user_scope === 'guest' || decodedToken.user_scope === 'Meeting Guest') {
          yield put(actions.showLoaderPage());
          if (localStorage.getItem('ns_t_cache')) {
            localStorage.setItem('ns_t', localStorage.getItem('ns_t_cache'));
            localStorage.removeItem('ns_t_cache');
            window.location.reload();
          } else {
            const apiHash = yield select((state) => state.configs.apiHash);
            nsApi.logout(apiHash).then(() => {
              window.location.reload();
            });
          }
        } else {
          const meetings = yield call(services.getMeetings, {
            domain: decodedToken.domain,
            user: decodedToken.user,
          });
          yield put(actions.setMeetings(meetings));
          yield put(events.showMeetingHubPage());
        }

        break;
      }
      case 'NO_MEETING': {
        yield put(actions.showMeetingNotFoundPage());
        break;
      }
      default:
        console.error(err);
        bugsnagClient.notify(err, (event) => {
          // eslint-disable-next-line no-param-reassign
          event.context = 'saga: processRoute';
        });
        yield put(actions.showUCLicenseMsgPage(err));
    }
  }
}

/**
 * Continuation of the load process after the conference id is verified
 */
export function* initConference() {
  console.debug('SAGA - initConference');

  decodedToken = nsToken.getDecoded();

  try {
    // videoMaxMeetings
    const res = yield services.getMeetings({
      user: decodedToken.user || decodedToken.userId,
      domain: decodedToken.domain,
    });

    const activeMeetings = _.filter(res, ({ status }) => ['active', 'started'].includes(status)).length;
    const videoMaxMeetings = yield select((state) => selectors.selectConfig(state, 'videoMaxMeetings'));

    if (videoMaxMeetings && activeMeetings >= videoMaxMeetings) {
      yield put(actions.showMeetingErrorPage('MAX_MEETINGS'));
      return;
    }
  } catch (err) {
    console.error(err);
    bugsnagClient.notify(err, (event) => {
      // eslint-disable-next-line no-param-reassign
      event.context = 'saga: initConference';
    });
  }

  const confId = yield select((state) => selectors.selectConfig(state, 'confId'));
  let attendeeId = yield select((state) => selectors.selectConfig(state, 'attendeeId'));

  let meeting;

  try {
    // read and set meeting by confId
    meeting = yield call(services.getMeetingById, {
      domain: decodedToken.domain,
      user: decodedToken.user,
      meetingId: confId,
      isInit: 1,
    });

    if (!meeting || !meeting.id) {
      const user = yield select((state) => selectors.selectUser(state));
      const nsTcache = localStorage.getItem('ns_t');
      const payload = {
        guest: decodedToken.displayName || (`${user.firstName} ${user.lastName}`),
        confId,
        guestEmail: user.email[0],
      };
      if (attendeeId) {
        payload.attId = attendeeId;
      }
      yield call(authenticateGuest, { payload });

      meeting = yield call(services.getMeetingById, {
        domain: decodedToken.domain,
        user: decodedToken.user,
        meetingId: confId,
        isInit: 1,
      });
      if (meeting) {
        localStorage.setItem('ns_t_cache', nsTcache);
      }
    }

    if (!meeting || !meeting.id) {
      yield put(actions.snackBarError('JOIN_CONF_ERR'));
      yield put(events.showMeetingHubPage());
      return;
    }
    const confInstance = meeting.instance_id;
    const meetingType = meeting.type;
    if (!attendeeId) {
      attendeeId = meeting.attendee_id;
      yield put(actions.setConfig({ attendeeId }));
    }
    // read and set sfu from confId
    const sfu = yield call(services.confIdCheck, {
      ...decodedToken, confId, confInstance, meetingType, attendeeId,
    });

    // set the sfu and videoBridgeId in the configs for later use and dev tooling
    const videoBridgeId = `sip:${confId}.video.bridge@conference-bridge`;
    yield put(actions.setConfig({ sfu }));
    yield put(actions.setConfig({ videoBridgeId }));

    yield call(setMeeting, { payload: meeting });

    if (meeting.lock) {
      const allowed = meeting.lock_allowed.replace(/(^,)|(,$)/g, '').split(',') || [];
      if (allowed && allowed.includes(meeting.attendee_id)) {
        // we are allowing the user back in here.
      } else {
        yield put(actions.showMeetingErrorPage('HOST_LOCKED_MEETING'));
        return;
      }
    }

    if (meeting.type === 'presentation') {
      const meetingStart = meeting.start_instance || meeting.start;
      const meetingEnd = meetingStart + meeting.duration;
      const nowInSeconds = Date.now() / 1000;

      if (meetingStart - nowInSeconds > 3600) {
        yield put(actions.showMeetingTimeErrorPage({ tense: 'future', timestamp: meetingStart }));
        return;
      }

      if (meetingEnd - nowInSeconds > 3600 || ['complete', 'ended'].includes(meeting.status)) {
        yield put(actions.showMeetingTimeErrorPage({ tense: 'past' }));
        return;
      }
    }
  } catch (err) {
    console.error(err);
    bugsnagClient.notify(err, (event) => {
      // eslint-disable-next-line no-param-reassign
      event.context = 'saga: initConference';
    });
    yield put(actions.snackBarError('JOIN_CONF_ERR'));
    throw err;
  }
  let url = `/video/?id=${confId}`;
  if (nsBrowser.getQuery('s')) url += `&s=${nsBrowser.getQuery('s')}`;
  if (nsBrowser.getQuery('v')) url += `&v=${nsBrowser.getQuery('v')}`;
  if (nsBrowser.getQuery('m')) url += `&m=${nsBrowser.getQuery('m')}`;
  window.history.pushState('video', meeting.name, url);

  const myAttendee = yield select(selectors.selectMyAttendee);

  if (myAttendee && myAttendee.access_restricted) {
    yield put(actions.showMeetingErrorPage('ACCESS_RESTRICTED'));
    return;
  }

  const isMobileView = yield select(selectors.selectMIV);

  const combinedId = yield select((state) => selectors.selectConfig(state, 'combinedId'));
  const hasDevices = yield services.hasDevices();
  const devices = yield getMediaDevices();
  let videoDevice = getDefaultVideo(devices);
  if (isMobileView === 1) {
    const { user, environment } = yield mapCameraDevices(devices);
    yield put(actions.setCameraMap({ user, environment }));
    if (localStorage.getItem('facingMode')) {
      videoDevice = getMIVVideo(devices, localStorage.getItem('facingMode') === 'user' ? user : environment);
    }
  }

  // prevent presentation mode user media for attendees
  if (meeting.type === 'conference'
  || (myAttendee && myAttendee.role && constants.ALLOWED_WEBINAR_ROLES.includes(myAttendee.role))) {
    // set the defaults
    yield put(actions.updateMediaStatus({
      userId: combinedId,
      audioMuted: true,
      audioStream: null,
      audioInputDevice: getDefaultAudioInput(devices),
      audioOutputDevice: getDefaultAudioOutput(devices),
      hasCamDevice: hasDevices.cam,
      hasMicDevice: hasDevices.mic,
      hasCamPermissions: null,
      hasMicPermissions: null,
      screenShareMuted: true,
      screenShareStream: null,
      videoAspectRatio: '16:9',
      videoDevice,
      videoMuted: true,
      videoStream: null,
    }));

    let camPermission;
    if (hasDevices.cam) {
      camPermission = checkMediaPermission({ audio: false, video: true });
    }

    let micPermission;
    if (hasDevices.mic) {
      micPermission = checkMediaPermission({ audio: true, video: false });
    }

    let { permissions } = yield race({
      permissions: all([
        micPermission,
        camPermission,
      ]),
      timeout: delay(3000),
    });

    if (permissions) {
      yield call(initMediaStreams, { audio: permissions[0], video: permissions[1], muted: false });
    } else {
      yield put(actions.showPendingPermissionsDialog(true));

      permissions = yield all([
        micPermission,
        camPermission,
      ]);

      yield call(initMediaStreams, { audio: permissions[0], video: permissions[1], muted: false });
    }
  } else {
    // let micPermission;
    // if (hasDevices.mic) {
    //   micPermission = yield checkMediaPermission({ audio: true, video: false });
    // }

    yield put(actions.updateMediaStatus({
      audioMuted: true,
      audioStream: null,
      audioInputDevice: getDefaultAudioInput(devices),
      audioOutputDevice: getDefaultAudioOutput(devices),
      hasCamDevice: hasDevices.cam,
      hasCamPermissions: null,
      hasMicDevice: hasDevices.mic,
      hasMicPermissions: null,
      screenShareMuted: true,
      screenShareStream: null,
      userId: combinedId,
      videoAspectRatio: null,
      videoDevice,
      videoMuted: true,
      videoStream: null,
    }));
  }

  // fork the user devices and registration process
  // save a ref to the task for joining later in the load process
  audioUaTask = yield fork(registerAudioUa, decodedToken);

  // if this line is reached, the conference id is valid
  // otherwise the catch will change the state to display the conf-id-form component
  // call the next generator function to continue the load process

  if (meeting.password_required === 1) {
    const suppliedPwd = yield select((state) => selectors.selectConfig(state, 'suppliedPwd'));
    nsBrowser.removeQuery('p');
    if (suppliedPwd && (String(suppliedPwd) === String(meeting.password)
      || String(suppliedPwd) === md5(String(meeting.password)))) {
      if (isMobileView === 1) {
        yield put(events.confSettingsSubmitted());
      } else {
        yield put(actions.showConfSettingsPage());
      }
    } else {
      yield put(actions.showMeetingPasswordPage());
    }
  } else if (isMobileView === 1) {
    yield put(events.confSettingsSubmitted());
  } else {
    yield put(actions.showConfSettingsPage());
  }

  if (nsBrowser.getQuery('ss') === '1') {
    yield put(events.startScreenShare());
    nsBrowser.removeQuery('ss');
  }

  yield call(emitMediaStatus);
  yield fork(nsSocketListeners);
  yield fork(nsSocketSubscribe);
}

/**
 * Finish init and display video conference
 */
export function* joinConf() {
  yield put(actions.showBgImage(false));
  const meeting = yield select(selectors.selectMeeting);
  const connectedAttendees = yield select(selectors.selectConnectedAttendees);

  // check if the socket is connected
  const isConnected = yield select(selectors.selectSocketConnected);
  if (!isConnected) {
    yield put(actions.openAlertDialog({
      title: 'SOCKET_CONNECTION_ERROR_TITLE',
      content: 'SOCKET_CONNECTION_ERROR_BODY',
      caption: 'SOCKET_CONNECTION_ERROR_CAPTION',
    }));
    return;
  }

  const myAttendee = yield select(selectors.selectMyAttendee);
  const max = yield select((state) => selectors.selectConfig(state, meeting.type === 'conference'
    ? 'videoConferenceMaxAttendees'
    : 'videoPresentationMaxAttendees'));

  // check max attendees, exception for user host role
  if (myAttendee.role !== 'host' && max && connectedAttendees.length > max) {
    yield put(actions.showMeetingErrorPage('MEETING_ROOM_FULL'));
    return;
  }

  yield put(actions.showLoaderPage());

  try {
    // pause the process until the async tasks are complete
    yield all([
      audioUaTask,
      userTask,
    ]);

    const user = yield select((state) => selectors.selectUser(state));
    const contacts = yield select((state) => selectors.selectContacts(state));
    const contact = matchContact(contacts, user.userId);
    if (contact) {
      user.firstName = contact.first_name;
      user.gravatar = contact.gravatar;
      user.initials = contact.initials;
      user.lastName = contact.last_name;
      user.displayName = contact.name;
    }

    // get call participants
    yield fork(addParticipants);

    // check if the user has a dial plan
    if (user.dialPlan) {
      // service checks for a dial rule, if not it will create one
      yield call(addDialRule, { dialPlan: user.dialPlan, domain: user.domain });
    }

    // set the audio user agent and call into the bridge
    const joinStatus = yield call(joinAudioBridge);
    if (joinStatus === AUDIO_TERMINATED) {
      // todo joining without audio toast
    } else {
      // Previously had audioUaReinviteHandler, but not needed it seems running 0.20.0 sip.js
    }

    // get mediaSoup device
    const deviceInfo = getDeviceInfo();
    yield put(actions.setConfig({ deviceInfo }));

    if (meeting.layout) {
      yield put(events.setLayout({ layout: meeting.layout }));
    }

    yield call(emitMediaStatus, deviceInfo);

    const userMediaStatus = yield select(selectors.selectUserMediaStatus);

    // prevent more than six producers in presentation mode
    let showMaxToast = false;
    if (meeting.type === 'presentation' && !userMediaStatus.videoMuted) {
      const attendees = yield select(selectors.selectAttendeesList);
      const count = _.filter(attendees, (attendee) => attendee.status_video === 'connected'
          && (attendee.role === 'host' || attendee.role === 'presenter').length);
      if (count >= 12) { // TODO add a ui config
        yield put(events.toggleVideoMute(false)); // recheck max = false
        showMaxToast = true;
      }
    }

    // this has to go after the logic above
    yield put(events.initRoom());

    // show the grid
    yield setTimeout(() => {
      store.dispatch(actions.showVideoGridPage(true));
    }, 1000);

    // this goes after showing the video grid page, it overwrites showing the snack message
    if (showMaxToast) {
      yield put(actions.snackBarMessage('WEBINAR_MAX_VIDEO_MESSAGE'));
    }
  } catch (err) {
    console.error(err);
    bugsnagClient.notify(err, (event) => {
      // eslint-disable-next-line no-param-reassign
      event.context = 'saga: joinConf';
    });
    yield put(actions.snackBarError('JOIN_CONF_ERR'));
    yield put(events.showMeetingHubPage());
  }
}

function getParameterByName(name) {
  const query = window.location.search.substring(1);

  const match = query.split('&').find(((attr) => {
    const { 0: key } = attr.split('=');
    return key === name;
  }));

  return match ? match.split('=')[1] : null;
}
